import React from "react";
import { Helmet } from "react-helmet";

export default function Seometa() {
    const canonicalUrl = window.location.href;
    return (
        <Helmet>
            <title>Buy Kesar Alphonso Mango, Sapota, vegetables – Riyaarth Exports</title>
            <meta name="description" content="Riyaarth Exports – Leading exporter of Kesar & Alphonso mango, G4 green chili, and fresh sapota. Best quality fruit supplier for GCC, UAE, and global markets." />
            <meta name="keywords" content="Mango exporter in India, Kesar mango export, Alphonso mango supplier, Fresh mango for export, Fresh Kesar mango, Premium Kesar mango export, Best quality Kesar mango supply, Buy Kesar mango online, Mango exporter to UAE, Farm-fresh Kesar mango wholesale, Natural Kesar mango for sale, International mango supplier, Indian mango export, Kesar Mango Suppliers India, Sweet Kesar Mango Export, High-Quality Mango, Buy Kesar Mango in Bulk, Kesar Mango Export to Dubai, Mango Wholesaler, Fresh Juicy Mango Export, Certified Mango Supplier, Fresh Alphonso mango, Premium Alphonso mango export, Best quality Alphonso mango supply, Buy Alphonso mango online, Farm-fresh Alphonso mango wholesale, Natural Alphonso mango for sale, Alphonso Mango Suppliers India, Sweet Alphonso Mango Export, Buy Alphonso Mango in Bulk, Alphonso Mango Export to Dubai, Green chili exporter, G4 green chili supplier, Buy fresh green chili, Fresh green chili, Premium G4 chili export, Best quality green chili supply, Buy green chili online, Chilli exporter to UAE, Farm-fresh green chili wholesale, Natural G4 chili for sale, International green chili supplier, Green Chili G4 Export, Green Chili Suppliers India, Fresh Green Chili Export, Indian Green Chili for Export, High-Quality G4 Chili, Buy Green Chili in Bulk, Green Chili Export to UAE, Green Chili Wholesaler, Fresh Spicy Chili Export, Certified Green Chili Supplier, Indian sapota exporter, Wholesale chikoo supplier, Fresh sapota fruits, Premium chikoo export, Best quality sapota supply, Buy sapota online, Sapota exporter to UAE, Farm-fresh chikoo wholesale, Natural sapota for sale, International sapota supplier, Fresh fruit exporter from India, Best fruit supplier, Agriculture export business, Indian fresh fruits for GCC, Fruit export company in India, Fresh fruit export, Fresh vegetable export" />
            <meta name="author" content="Riyaarth Exports" />
            <meta name="robots" content="index, follow" />
            <link rel="canonical" href={canonicalUrl} />
        </Helmet>
    );
}
