import React from "react";
import { Container, Row, Col, Image, Card } from "react-bootstrap";
import { FaLeaf, FaHandsHelping, FaWarehouse, FaUsers } from "react-icons/fa";
import Mixveg from "../../assets/img/mixveggies.png";
const Services = () => {
  return (
    <Container className="my-5">
      <h2 className="text-center fw-bold mb-4">Quality Fresh & Organic Produce Selection</h2>

      <Row className="align-items-center">
        {/* Image Section */}
        <Col md={6} className="text-center">
          <Image src={Mixveg} width={"60%"} height={"100%"} alt="Export quality fresh mango, sapota and Veggies" title="Export quality fresh mango, sapota and Veggies" fluid rounded />
        </Col>

        {/* Text Section */}
        <Col md={6}>
          <Row className="g-4">
            <Col xs={12}>
              <Card className="border-0 shadow-sm p-3">
                <Row className="align-items-center">
                  <Col xs={2}>
                    <FaLeaf className="text-success fs-3" />
                  </Col>
                  <Col>
                    <h5 className="fw-bold">Quality Control</h5>
                    <p className="text-muted mb-0">
                      Serving quality products to our clients is our utmost priority...
                    </p>
                  </Col>
                </Row>
              </Card>
            </Col>

            <Col xs={12}>
              <Card className="border-0 shadow-sm p-3">
                <Row className="align-items-center">
                  <Col xs={2}>
                    <FaHandsHelping className="text-success fs-3" />
                  </Col>
                  <Col>
                    <h5 className="fw-bold">Contract Farming</h5>
                    <p className="text-muted mb-0">
                      An institutional arrangement in which both producers and processors...
                    </p>
                  </Col>
                </Row>
              </Card>
            </Col>

            <Col xs={12}>
              <Card className="border-0 shadow-sm p-3">
                <Row className="align-items-center">
                  <Col xs={2}>
                    <FaWarehouse className="text-success fs-3" />
                  </Col>
                  <Col>
                    <h5 className="fw-bold">Service Packhouse</h5>
                    <p className="text-muted mb-0">
                      We run our packhouse professionally in Amalsad under NPPO guidelines.
                    </p>
                  </Col>
                </Row>
              </Card>
            </Col>

            <Col xs={12}>
              <Card className="border-0 shadow-sm p-3">
                <Row className="align-items-center">
                  <Col xs={2}>
                    <FaUsers className="text-success fs-3" />
                  </Col>
                  <Col>
                    <h5 className="fw-bold">Quality Workers</h5>
                    <p className="text-muted mb-0">
                      We ensure skilled labor for handling and packaging to maintain high standards.
                    </p>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default Services;
