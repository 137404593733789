import React, { useEffect } from "react";
import styled from "styled-components";
import DOMPurify from "dompurify";
import EmptyTopNavbar from "../components/Nav/EmptyTopNavbar";
import products from "../components/Data/ProductList.json";
import { useParams, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import SocialShare from "../components/Elements/SocialShare";
import Footer from "../components/Sections/Footer";

export default function ProductDescription() {
  const canonicalUrl = window.location.href;
  const navigate = useNavigate();
  const { id } = useParams();

  const product = products.find((p) => p.id.toString() === id);

  useEffect(() => {
    if (!product) {
      setTimeout(() => navigate("/"), 2000);
    }
  }, [product, navigate]);

  if (!product) {
    return (
      <Wrapper>
        <h2 style={{ textAlign: "center", color: "red" }}>Product not found. Redirecting to home in 2 Sec...</h2>
      </Wrapper>
    );
  }

  const handleClick = (url) => {
    navigate("/");
    setTimeout(() => {
      if (url === "/#products") {
        const productSection = document.getElementById("products");
        if (productSection) {
          productSection.scrollIntoView({ behavior: "smooth" });
        }
      }
    }, 100);
  };

  return (
    <>
      <Helmet>
        <title>{product.name} – Riyaarth Exports</title>
        <meta name="keywords" content={product.seokeywords}></meta>
        <meta name="description" content={product.seodescription}></meta>
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
      <EmptyTopNavbar />
      <div className="container">
        <Wrapper id="product-description">
          <LeftSide>
            <img className="radius8 center" src={product.image} alt={product.name} title={product.name} width={"85%"} loading="lazy" />
          </LeftSide>
          <RightSide>
            <Breadcrumbs>
              <h6 role="button" tabIndex="0" onClick={() => handleClick("/#home")}>Home</h6>
              /
              <h6 role="button" tabIndex="0" onClick={() => handleClick("/#products")}>Product List</h6>
            </Breadcrumbs>

            <h1 dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(product.name) }}></h1>

            <SocialShare product={product} />
            <hr />
            <BtnWrapper>
              <a
                href="https://api.whatsapp.com/send?phone=9196987001947"
                className="cta-button"
                target="_blank"
                rel="noopener noreferrer"
              >
                Get Rates !
              </a>
            </BtnWrapper>
            {/* <p className="price">Price: <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(product.price) }}></span></p> */}
            <br />
            <p className="description" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(product.descriptionone) }}></p>
            <br />
            <p className="description" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(product.descriptiontwo) }}></p>
          </RightSide>
        </Wrapper>

        <GalleryWrapper>
          <h2>Product Image Gallery</h2>
          <ImageGallery>
            {product.images &&
              product.images.slice(0, 15).map((img, index) => (
                <GalleryImg key={index} src={img} alt={product.name} title={product.name} loading="lazy" />
              ))}
          </ImageGallery>
        </GalleryWrapper>
      </div>
      <div className="spacer"></div>
      <br /><br />
      <Footer />
    </>
  );
}

const Wrapper = styled.section`
  margin-top: 120px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 24px;

  @media (max-width: 1023px) {
    flex-direction: column;
  }
`;

const LeftSide = styled.div`
  width: 35%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
  }

  @media (max-width: 960px) {
    width: 100%;
    order: 2;
    margin: 50px 0;
    text-align: center;
  }
`;

const RightSide = styled.div`
  width: 65%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 1;
    margin-top: 30px;
  }
`;

const Breadcrumbs = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  margin-bottom: 50px;

  h6 {
    margin: 0;
    cursor: pointer;
    text-decoration: underline;
    &:hover {
      color: blue;
    }
  }
`;

const GalleryWrapper = styled.div`
  margin-top: 60px;
  text-align: center;
  h3 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }
`;

const ImageGallery = styled.div`
  display: flex;
  gap: 10px;
  overflow-x: auto;
  justify-content: center;
  margin-top: 15px;
  flex-wrap: wrap;
`;

const GalleryImg = styled.img`
  width: 220px;
  height: 220px;
  object-fit: cover;
  border-radius: 5px;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
  
  &:hover {
    transform: scale(1.1);
  }
`;

const BtnWrapper = styled.div`
  max-width: 190px;
  @media (max-width: 960px) {
    margin: 0 auto;
  }
  .cta-button {
    display: inline-block;
    padding: 10px 20px;
    background-color:rgb(6, 188, 212);
    color: #fff;
    text-decoration: none;
    font-weight: bold;
    border-radius: 5px;
    transition: background 0.3s;
  }
  .cta-button:hover {
    background-color:rgb(0, 156, 204);
  }
`;
