import React from "react";
import { FaFacebook, FaTwitter, FaLinkedin, FaWhatsapp, FaTelegram } from "react-icons/fa";

export default function SocialShare({ product }) {
  const productUrl = encodeURIComponent(window.location.href); // Encoded URL
  const productTitle = encodeURIComponent(product.name); // Encoded Product Title

  const shareLinks = {
    facebook: `https://www.facebook.com/sharer/sharer.php?u=${productUrl}`,
    twitter: `https://twitter.com/intent/tweet?url=${productUrl}&text=${productTitle}`,
    linkedin: `https://www.linkedin.com/sharing/share-offsite/?url=${productUrl}`,
    whatsapp: `https://api.whatsapp.com/send?text=${productTitle}%20${productUrl}`,
    telegram: `https://t.me/share/url?url=${productUrl}&text=${productTitle}`,
  };

  return (
    <div className="social-share">
      <div className="icons">
        <a href={shareLinks.facebook} target="_blank" rel="noopener noreferrer" style={{'margin-right': '10px'}}>
          <FaFacebook size={22} color="#1877F2" />
        </a>
        <a href={shareLinks.twitter} target="_blank" rel="noopener noreferrer" style={{'margin-right': '10px'}}>
          <FaTwitter size={22} color="#1DA1F2" />
        </a>
        <a href={shareLinks.linkedin} target="_blank" rel="noopener noreferrer" style={{'margin-right': '10px'}}>
          <FaLinkedin size={22} color="#0A66C2" />
        </a>
        <a href={shareLinks.whatsapp} target="_blank" rel="noopener noreferrer" style={{'margin-right': '10px'}}>
          <FaWhatsapp size={22} color="#25D366" />
        </a>
        <a href={shareLinks.telegram} target="_blank" rel="noopener noreferrer" style={{'margin-right': '10px'}}>
          <FaTelegram size={22} color="#0088CC" />
        </a>
      </div>
    </div>
  );
}
