import React from "react";
import { Container } from "react-bootstrap";
import EmptyTopNavbar from "../components/Nav/EmptyTopNavbar";
import Footer from "../components/Sections/Footer";

import SeoMeta from "../components/Elements/Seometa";
export default function PrivacyPolicy() {

  return (
    <Container className="py-5">

      <SeoMeta />
      <EmptyTopNavbar />
      <br /><br /><br /><br />
      <h2 className="text-center mb-4">Privacy Policy</h2>
      <p><strong>Effective Date:</strong> 29/12/2024</p>

      <h4>1. Information We Collect</h4>
      <p>We may collect personal details like name, email, phone number, and business information to process your orders and improve our services.</p>

      <h4>2. How We Use Your Information</h4>
      <ul>
        <li>To process orders and shipments.</li>
        <li>To provide customer support and respond to inquiries.</li>
        <li>To improve our services and website experience.</li>
        <li>To comply with legal and regulatory requirements.</li>
      </ul>

      <h4>3. Data Protection & Security</h4>
      <p>We take necessary security measures to protect your data from unauthorized access or misuse.</p>

      <h4>4. Sharing of Information</h4>
      <p>We do not sell your data. We may share it with shipping partners, payment processors, and government authorities when required.</p>

      <h4>5. Cookies & Tracking</h4>
      <p>Our website uses cookies to enhance user experience. You can disable cookies in your browser settings.</p>

      <h4>6. Your Rights</h4>
      <p>You have the right to access, update, or delete your personal data. Contact us at <strong>info@riyaarthexports.com</strong>.</p>

      <h4>7. Changes to This Policy</h4>
      <p>We may update this policy periodically. Changes will be posted on our website.</p>

      <h4>8. Contact Us</h4>
      <p><strong>Riyaarth Exports</strong></p>
      <p>📧 Email: vinit@riyaarthexports.com</p>
      <p>📞 Phone: +91 9687011947</p>
      <br /><br />
      <Footer />
    </Container>
  );
}
