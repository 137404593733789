import React from "react";
import { Container } from "react-bootstrap";
import EmptyTopNavbar from "../components/Nav/EmptyTopNavbar";
import Footer from "../components/Sections/Footer";
import SeoMeta from "../components/Elements/Seometa";
export default function TermsAndConditions() {

  return (

    <Container className="py-5">
      <SeoMeta />

      <EmptyTopNavbar />
      <br /><br /><br /><br />
      <h2 className="text-center mb-4">Terms & Conditions</h2>
      <p><strong>Effective Date:</strong> 29/12/2024</p>

      <h4>1. Introduction</h4>
      <p>Welcome to Riyaarth Exports. By accessing our website or using our services, you agree to comply with these Terms & Conditions. Please read them carefully.</p>

      <h4>2. Use of Our Services</h4>
      <p>By using our services, you agree:</p>
      <ul>
        <li>To provide accurate and up-to-date information.</li>
        <li>Not to engage in any fraudulent or illegal activities.</li>
        <li>To use our website and services in compliance with applicable laws.</li>
      </ul>

      <h4>3. Orders & Payments</h4>
      <ul>
        <li>All orders are subject to product availability and confirmation.</li>
        <li>Prices are subject to change without prior notice.</li>
        <li>Payments must be made according to agreed terms.</li>
      </ul>

      <h4>4. Shipping & Delivery</h4>
      <ul>
        <li>We aim for timely delivery, but delays may occur due to unforeseen circumstances.</li>
        <li>The risk of goods passes to the buyer once the shipment is dispatched.</li>
      </ul>

      <h4>5. Returns & Refunds</h4>
      <p>Returns and refunds are subject to our return policy. Customers must notify us within 48 hours of receiving defective or incorrect products.</p>

      <h4>6. Limitation of Liability</h4>
      <p>Riyaarth Exports shall not be liable for any indirect, incidental, or consequential damages arising from the use of our services.</p>

      <h4>7. Intellectual Property</h4>
      <p>All content on this website, including logos, text, and images, is the property of Riyaarth Exports. Unauthorized use is prohibited.</p>

      <h4>8. Governing Law</h4>
      <p>These Terms & Conditions are governed by the laws of India. Any disputes shall be resolved in the courts of Gujarat.</p>

      <h4>9. Changes to Terms</h4>
      <p>We may update these Terms & Conditions from time to time. Changes will be posted on our website.</p>

      <h4>10. Contact Us</h4>
      <p><strong>Riyaarth Exports</strong></p>
      <p>📧 Email: vinit@riyaarthexports.com</p>
      <p>📞 Phone: +91 9687011947</p>
      <br /><br />
      <Footer />
    </Container>

  );
}
