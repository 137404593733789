// import React from "react";
// import styled from "styled-components";
// import { Link } from "react-scroll";
// import FollowUs from "../Elements/FollowUs";
// // Asset
// import LogoImg from "../../assets/svg/Logo";
// export default function Footer() {
//   const getCurrentYear = () => {
//     return new Date().getFullYear();
//   }

//   return (
//     <Wrapper>
//       <div className="darkBg">
//         <div className="container">
//           <InnerWrapper className="flexSpaceCenter" style={{ padding: "30px 0" }}>
//             <Link className="flexCenter animate pointer" to="home" smooth={true} offset={-80}>
//               <LogoImg />
//               <h2 className="font15 extraBold whiteColor" style={{ marginLeft: "15px" }}>
//                 Riyaarth Exports
//               </h2>
//             </Link>
//             <StyleP className="whiteColor font13">
//               © {getCurrentYear()} - <span className="greenColor font13">Riyaarth Exports</span> All Right Reserved
//             </StyleP>
//             <FollowUs />

//             <Link className="whiteColor animate pointer font13" to="home" smooth={true} offset={-80}>
//               Back to top
//             </Link>

//           </InnerWrapper>
//         </div>
//       </div>
//     </Wrapper>
//   );
// }

// const Wrapper = styled.div`
//   width: 100%;
// `;
// const InnerWrapper = styled.div`
//   @media (max-width: 550px) {
//     flex-direction: column;
//   }
// `;
// const StyleP = styled.p`
//   @media (max-width: 550px) {
//     margin: 20px 0;
//   }
// `;


import React from "react";
import { Link } from "react-scroll";
import LogoImg from "../../assets/img/RXLOGO.png";
import { Container, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
// import { FaFacebookF, FaTwitter, FaInstagram, FaWhatsapp } from "react-icons/fa";
import FollowUs from "../Elements/FollowUs";
export default function Footer() {

  const navigate = useNavigate();
 
  const handleNavigateToAbout = () => {
    navigate("/aboutus");
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" }); // ✅ Ensure immediate start
    }, 10);
  };
  const handleNavigateToPolicy = () => {
    navigate("/privacy-policy");
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" }); // ✅ Ensure immediate start
    }, 10);
  };

  const handleNavigateToTerms = () => {
    navigate("/terms-and-conditions");
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" }); // ✅ Ensure immediate start
    }, 10);
  };

  return (
    <footer className="bg-light text-dark py-4">
      <Container>
        <Row className="gy-4">

          {/* Company Info */}
          <Col md={3}>
            <img src={LogoImg} alt="Riyaarth Exports" className="mb-3" style={{ height: "50px" }} />
            <p className="small">World-class Exporting Excellence</p>
            <p className="small"><strong>📞</strong> +91 9687011947</p>
            <p className="small"><strong>✉️</strong> vinit@riyaarthexports.com</p>
          </Col>

          {/* Categories */}
          <Col md={3}>
            <h5 className="fw-bold">CATEGORIES</h5>
            <ul className="list-unstyled small">
              <li>
                <Link activeClass="active" to="products" spy={true} smooth={true} duration={200} offset={-80}>
                  <p className="pointer">Fresh Fruits</p>
                </Link>
              </li>
              <li><Link activeClass="active" to="products" spy={true} smooth={true} duration={200} offset={-80}>
                <p className="pointer">Vegetables</p>
              </Link></li>

            </ul>
          </Col>

          {/* About Us */}
          <Col md={3}>
            <h5 className="fw-bold">ABOUT US</h5>
            <ul className="list-unstyled small">
              <li>
                <p className="pointer" onClick={handleNavigateToAbout}>About Us</p>
              </li>
              <li>
                <p className="pointer" onClick={handleNavigateToPolicy}>Privacy Policy</p>
              </li>
              <li>
                <p className="pointer" onClick={handleNavigateToTerms}>Terms & Conditions</p>
              </li>
            </ul>
          </Col >

          {/* App Download & Social Links */}
          < Col md={3} >
            {/* <h5 className="fw-bold">DOWNLOAD APP</h5>
            <div className="d-flex gap-2 mb-3">
              <img src="/google-play.png" alt="Google Play" className="img-fluid" style={{ height: "30px" }} />
              <img src="/app-store.png" alt="App Store" className="img-fluid" style={{ height: "30px" }} />
            </div> */}
            < h5 className="fw-bold" > GET IN TOUCH</h5 >
            <FollowUs />
            {/* <div className="d-flex gap-3">
              <FaFacebookF className="fs-4" />
              <FaTwitter className="fs-4" />
              <FaInstagram className="fs-4" />
              <FaWhatsapp className="fs-4" />
            </div> */}
          </Col >

        </Row >

        {/* Copyright */}
        < hr className="my-3" />
        <Row className="gy-4">
          <Col md={10}>
            <p className="text-center small mb-0">
              © {new Date().getFullYear()} <strong>Riyaarth Exports</strong>. All Rights Reserved.
            </p>
          </Col>
          <Col md={2}>
            <Link className="animate pointer font13 text-center small mb-0 black" to="home" smooth={true} offset={-80}>
              Back to top
            </Link>
          </Col>
        </Row>


      </Container >
    </footer >
  );
}
