import React, { useState } from "react";
import { Container, Row, Col, Card, Modal, Button } from "react-bootstrap";
import SeoMeta from "../components/Elements/Seometa";
import EmptyTopNavbar from "../components/Nav/EmptyTopNavbar";
import Service from "../components/Sections/Services";
//import teamPhoto from "../assets/img/add/riyaarth.jpg";
import Footer from "../components/Sections/Footer";
const certificates = [
  { id: 1, name: "MSME / UDYAM Certification", imgSrc: "../assets/img/certificates/RIYAARTH_EXPORTS_MSME.png" },
  { id: 2, name: "FSSI Certification", imgSrc: "../assets/img/certificates/RIYAARTH_EXPORTS_FSSI.png" },
  { id: 3, name: "Export License (IEC)", imgSrc: "../assets/img/certificates/RIYAARTH_EXPORTS_IEC.png" },
  { id: 4, name: "APEDA Certification", imgSrc: "../assets/img/certificates/RIYAARTH_EXPORTS_APEDA.png" },
];

export default function About() {
  const [selectedCert, setSelectedCert] = useState(null);
  const [zoom, setZoom] = useState(1);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [dragging, setDragging] = useState(false);
  const [startPos, setStartPos] = useState({ x: 0, y: 0 });

  const handleShow = (cert) => {
    setSelectedCert(cert);
    setZoom(1); // Reset zoom
    setPosition({ x: 0, y: 0 }); // Reset position
  };

  const handleClose = () => setSelectedCert(null);

  const handleZoom = (factor) => {
    setZoom((prevZoom) => Math.max(1, prevZoom + factor));
  };

  const handleWheelZoom = (e) => {
    e.preventDefault();
    const zoomFactor = e.deltaY > 0 ? -0.1 : 0.1;
    setZoom((prevZoom) => Math.max(1, prevZoom + zoomFactor));
  };

  const handleMouseDown = (e) => {
    setDragging(true);
    setStartPos({ x: e.clientX - position.x, y: e.clientY - position.y });
  };

  const handleMouseMove = (e) => {
    if (!dragging) return;
    setPosition({ x: e.clientX - startPos.x, y: e.clientY - startPos.y });
  };

  const handleMouseUp = () => setDragging(false);

  return (
    <>
      {/* SEO Metadata */}
      <SeoMeta />

      <EmptyTopNavbar />

      <Container style={{ marginTop: "130px" }}>
        <h1 className="text-center mb-4">About Our Team</h1>
        <Row>
         {/* <Col md={6} className="text-center">
            <img
              src={teamPhoto}
              alt="Riyaarth Exports Team"
              className="img-fluid rounded"
              width="100%"
              height="auto"
              loading="lazy"
            />
          </Col>  */}
          <Col md={12}>
            <h2 className="mb-3">Riyaarth Exports – certified producer cum-merchant exporter</h2>
            <p>
              Riyaarth Exports is a trusted <strong>producer cum-merchant exporter</strong> specializing in <strong>sapota, mango and other agro based product</strong> exports from Navsari, India.
              Our dedicated team ensures premium quality fruits reach international buyers.
            </p>
            <br /><br />
            <h5><u>Our Leadership</u></h5>
            <p><strong>Maheshvari Vinit Patel</strong> – Chief Executive Officer(CEO)</p>
           
          </Col>
        </Row>
      </Container>
      <Service />

      {/* Certifications Section */}
      <Container className="mt-5">
        <h2 className="text-center mb-4">Our Certifications</h2>
        <Row className="gy-4">
          {certificates.map((cert) => (
            <Col key={cert.id} xs={12} sm={6} md={4} className="d-flex justify-content-center">
              <Card
                style={{ width: "100%", maxWidth: "300px", cursor: "pointer" }}
                className="shadow-sm"
                onClick={() => handleShow(cert)}
                aria-label={`View ${cert.name}`}
              >

                <Card.Body className="text-center">
                  <Card.Title className="font-weight-bold">{cert.name}</Card.Title>
                </Card.Body>

                <Card.Img
                  variant="top"
                  src={cert.imgSrc}
                  alt={cert.name}
                  className="img-fluid"
                  loading="lazy"
                />
              </Card>
            </Col>
          ))}
        </Row>
      </Container>

      {/* Modal Popup with Zoom & Drag */}
      <Modal show={!!selectedCert} onHide={handleClose} centered size="lg">
        {selectedCert && (
          <>
            <Modal.Header closeButton>
              <Modal.Title>{selectedCert.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">
              <div
                style={{
                  width: "100%",
                  overflow: "hidden",
                  position: "relative",
                  cursor: dragging ? "grabbing" : "grab",
                }}
                onWheel={handleWheelZoom}
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
                onMouseLeave={handleMouseUp}
              >
                <img
                  src={selectedCert.imgSrc}
                  alt={selectedCert.name}
                  className="img-fluid"
                  style={{
                    transform: `scale(${zoom}) translate(${position.x}px, ${position.y}px)`,
                    transition: dragging ? "none" : "transform 0.3s ease",
                  }}
                  loading="lazy"
                />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="outline-primary" onClick={() => handleZoom(0.2)}>
                Zoom In +
              </Button>
              <Button variant="outline-primary" onClick={() => handleZoom(-0.2)}>
                Zoom Out -
              </Button>
              <Button variant="outline-danger" onClick={() => setZoom(1)}>
                Reset
              </Button>
            </Modal.Footer>
          </>
        )}
      </Modal>
      <br /><br />
      <Footer />
    </>
  );
}
