import React, { useEffect, useState } from "react";
import styled from "styled-components";
// Components
import { Link } from "react-scroll";
// Assets
import Logo from "../../assets/img/RXLOGO.png";
import { useNavigate } from "react-router-dom";

export default function EmptyTopNavbar() {
  const [y, setY] = useState(window.scrollY);

  useEffect(() => {
    window.addEventListener("scroll", () => setY(window.scrollY));
    return () => {
      window.removeEventListener("scroll", () => setY(window.scrollY));
    };
  }, [y]);

  const navigate = useNavigate();
    const handleClick = () => {
      navigate("/");
    };


  return (
    <>
     <ContactInfo>
        <strong>📞</strong> +91 9687011947 &nbsp;
        <strong>✉️</strong> vinit@riyaarthexports.com
      </ContactInfo>
      <Wrapper className="flexCenter animate whiteBg" style={y > 100 ? { height: "120px" } : { height: "120px" }}>
        <NavInner className="container flexSpaceCenter">
          <Link className="pointer flexNullCenter" to="home" smooth={true}>
            <img src={Logo} alt="Riyaarth Exports" width={230} height={100} onClick ={handleClick}/>
          </Link>
        </NavInner>
      </Wrapper>
    </>
  );
}

const ContactInfo = styled.div`
  position: fixed;
  top: 10px;
  right: 20px;
  font-size: 12px;
  font-weight: bold;
  z-index: 1000;
`;

const Wrapper = styled.nav`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
`;
const NavInner = styled.div`
  position: relative;
  height: 100%;
`