import React from "react";
import ReactDOM from "react-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./style/flexboxgrid.min.css";
import "./style/index.css";
import App from "./App";
import ProductDiscription from "./screens/ProductDiscription";
import About from "./screens/About";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import TermsAndConditions from "./screens/TermsAndConditions";
import PrivacyPolicy from "./screens/PrivacyPolicy";

ReactDOM.render(
  <BrowserRouter>
    <Routes>
      {/* Main Page */}
      <Route path="/" element={<App />} />

      {/* SEO-Optimized Routes for Mango Products */}
      <Route path="/buy-kesar-mango-online-from-india" element={<App />} />
      <Route path="/fresh-kesar-mango-exporters-india" element={<App />} />
      <Route path="/premium-alphonso-mango-suppliers" element={<App />} />
      <Route path="/best-quality-alphonso-mango-online" element={<App />} />
      <Route path="/mango-export-to-dubai-saudi-arabia" element={<App />} />
      <Route path="/bulk-mango-suppliers-in-uae-qatar" element={<App />} />
      <Route path="/fresh-kesar-mango-price-in-middle-east" element={<App />} />

      {/* SEO-Optimized Routes for Fruit Exports */}
      <Route path="/indian-mango-wholesale-market-uae" element={<App />} />
      <Route path="/fresh-mango-air-shipment-to-bahrain" element={<App />} />
      <Route path="/indian-fruit-exporters-riyaarth" element={<App />} />
      <Route path="/best-fruit-exporters-in-india" element={<App />} />
      <Route path="/mango-sapota-fruit-suppliers" element={<App />} />
      <Route path="/bulk-fruits-export-to-middle-east" element={<App />} />
      <Route path="/fresh-fruits-suppliers-uae-ksa-qatar" element={<App />} />
      <Route path="/riyaarth-exports-mango-sapota" element={<App />} />
      <Route path="/best-fruit-export-deals-middle-east" element={<App />} />
      <Route path="/fresh-fruit-wholesale-market-india" element={<App />} />
      <Route path="/order-mango-sapota-online-in-bulk" element={<App />} />

      {/* SEO-Optimized Routes for Sapota (Chikoo) */}
      <Route path="/buy-fresh-sapota-chikoo-online" element={<App />} />
      <Route path="/export-quality-sapota-chikoo-suppliers" element={<App />} />
      <Route path="/fresh-sapota-price-for-bulk-export" element={<App />} />
      <Route path="/premium-quality-chikoo-from-india" element={<App />} />
      <Route path="/sapota-fruit-wholesale-market-dubai" element={<App />} />
      <Route path="/best-sapota-exporters-to-kuwait-qatar" element={<App />} />
      <Route path="/indian-chikoo-fruit-suppliers-oman" element={<App />} />
      <Route path="/fresh-sapota-for-sale-in-middle-east" element={<App />} />
      <Route path="/bulk-chikoo-fruit-export-to-usa" element={<App />} />
      <Route path="/fresh-sapota-chikoo-online-store" element={<App />} />

      {/* Dynamic Product Description Page */}
      <Route path="/product/:id" element={<ProductDiscription />} />
      <Route path="/aboutus" element={<About />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
      {/* Default Route for Index (Redundant but useful) */}
      <Route index element={<App />} />
    </Routes>
  </BrowserRouter>,
  document.getElementById("root")
);

reportWebVitals();
