import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
import { useNavigate } from "react-router-dom"; // ✅ Import useNavigate
import Sidebar from "../Nav/Sidebar";
import Backdrop from "../Elements/Backdrop";
import Logo from "../../assets/img/RXLOGO.png";
import BurgerIcon from "../../assets/svg/BurgerIcon";

export default function TopNavbar() {
  const [y, setY] = useState(window.scrollY);
  const [sidebarOpen, toggleSidebar] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    window.addEventListener("scroll", () => setY(window.scrollY));
    return () => {
      window.removeEventListener("scroll", () => setY(window.scrollY));
    };
  }, [y]);

  // ✅ Function to navigate & scroll to top
  const handleNavigateToAbout = () => {
    navigate("/aboutus");
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" }); // ✅ Ensure immediate start
    }, 10);
  };

  return (
    <>
      <ContactInfo>
        <strong>📞</strong> +91 9687011947 &nbsp;
        <strong>✉️</strong> vinit@riyaarthexports.com
      </ContactInfo>
      <Sidebar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
      {sidebarOpen && <Backdrop toggleSidebar={toggleSidebar} />}
      <Wrapper className="flexCenter animate whiteBg" style={y > 100 ? { height: "120px" } : { height: "120px" }}>
        <NavInner className="container flexSpaceCenter">
          <Link className="pointer flexNullCenter" to="home" smooth={true}>
            <img src={Logo} alt="Riyaarth Exports" title="Riyaarth Exports" width={230} height={100} />
          </Link>
          <BurderWrapper className="pointer" onClick={() => toggleSidebar(!sidebarOpen)}>
            <BurgerIcon />
          </BurderWrapper>
          <UlWrapper className="flexNullCenter">
            <li className="semiBold font15 pointer" style={{ padding: "10px 15px" }}>
              <Link activeClass="active" to="home" spy={true} smooth={true} duration={200} offset={-80}>
                Home
              </Link>
            </li>
            <li className="semiBold font15 pointer" style={{ padding: "10px 15px" }}>
              <Link activeClass="active" to="products" spy={true} smooth={true} duration={200} offset={-80}>
                Products
              </Link>
            </li>
            <li className="semiBold font15 pointer" style={{ padding: "10px 15px" }}>
              <Link activeClass="active" to="contact" spy={true} smooth={true} duration={200} offset={-80}>
                Contact
              </Link>
            </li>
            <li className="semiBold font15 pointer" style={{ padding: "10px 15px" }} onClick={handleNavigateToAbout}>
              About Us
            </li>
          </UlWrapper>
        </NavInner>
      </Wrapper>
    </>
  );
}

// Styled Components
const ContactInfo = styled.div`
  position: fixed;
  top: 10px;
  right: 20px;
  font-size: 12px;
  font-weight: bold;
  z-index: 1000;
`;

const Wrapper = styled.nav`
  width: 100%;
  position: fixed;
  top: 10;
  left: 0;
  z-index: 999;
`;

const NavInner = styled.div`
  position: relative;
  height: 100%;
`;

const BurderWrapper = styled.button`
  outline: none;
  border: 0px;
  background-color: transparent;
  height: 100%;
  padding: 0 15px;
  display: none;
  @media (max-width: 760px) {
    display: block;
  }
`;

const UlWrapper = styled.ul`
  display: flex;
  @media (max-width: 760px) {
    display: none;
  }
`;
