import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FaLinkedin, FaFacebook, FaYoutube, FaInstagram, FaWhatsapp } from 'react-icons/fa';

const FollowUs = () => {
  const followUsContent = (
    <>

      <a href="https://www.linkedin.com/in/vinit-patel-agri-produce-import-advisor-848b3492/" target="_blank" rel="noopener noreferrer" className="mb-2 text-primary">
        <FaLinkedin size={22} />
      </a>
      <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer" className="mb-2 text-primary">
        <FaFacebook size={22} />
      </a>
      <a href="https://www.youtube.com/@RiyaarthExports" target="_blank" rel="noopener noreferrer" className="mb-2 text-danger">
        <FaYoutube size={22} />
      </a>
      <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer" className="text-danger">
        <FaInstagram size={22} />
      </a>
      <a href="https://api.whatsapp.com/send??phone=9196987001947&text='Hello..'" target="_blank" rel="noopener noreferrer" style={{color:'#25D366'}}>
        <FaWhatsapp size={22} />
      </a>
    </>
  );

  return (
    <>
      {/* Sidebar for larger screens */}
      {/* <div className="d-none d-md-flex flex-column align-items-center position-fixed top-50 start-0 translate-middle-y bg-light p-3 shadow rounded">
        {followUsContent}
      </div> */}

      {/* Footer for mobile devices */}
      {/* <footer className="d-flex d-md-none p-1 text-center">
        {followUsContent}
      </footer> */}
      <div className="d-flex gap-3">
        {followUsContent}
      </div>
    </>
  );
};

export default FollowUs;
