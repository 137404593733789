import React from "react";

import TopNavbar from "../components/Nav/TopNavbar";
import Header from "../components/Sections/Header";
import Products from "../components/Sections/Product";
import Contact from "../components/Sections/Contact";
import SeoMeta from "../components/Elements/Seometa";
import Footer from "../components/Sections/Footer";
export default function Landing() {
 
  return (
    <>
      <TopNavbar />
      <SeoMeta />
      <Header />
      <Products />
      <Contact />
      <Footer />
    </>
  );
}

