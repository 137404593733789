import React from "react";
import styled from "styled-components";
import { Container, Row, Col, Card } from "react-bootstrap";
export default function Contact() {
  return (
    <Wrapper id="contact">
      <div className="lightBg">
        <div className="container">
          <HeaderInfo>
            <h2 className="font40 extraBold">Let's get in touch</h2>
            <br />
            <Container className="mt-4">
              <Row>
                {/* <Col md={5} sm={12}>
                    <Table>
                      <tbody>
                        <tr>
                          <td><b>Address:</b></td>
                          <td>
                            2415, Shri Rang Sadhana Society <br />
                            Saribujarang, Amalsad, <br />Ta: Gandevi, Di: Navsari <br />
                            PIN: 396310
                          </td>
                        </tr>
                        <tr>
                          <td><b>E-mail:</b></td>
                          <td>riyaarthexports@gmail.com <br /> riyaarthexim@gmail.com</td>
                        </tr>
                        <tr>
                          <td><b>Contact:</b></td>
                          <td>+91 9687011947</td>
                        </tr>
                      </tbody>
                    </Table>
                </Col> */}
                <Col md={12} sm={12}>
                  <Card className="p-2">
                    <div className="ms-0 w-100 w-md-70 mt-4 mt-md-0">
                      <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5000!2d-5.0882693!3d20.8172491!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be0f1519d2e83bd%3A0x643c3f16d09c1075!2sRiyaarth%20Exports!5e0!3m2!1sen!2sin!4v1619020634997!5m2!1sen!2sin"
                        width="100%"
                        height="400px"
                        allowFullScreen=""
                        loading="lazy"
                        title="Riyaarth Experts - Headquarters"
                        className="rounded shadow-lg"
                      ></iframe>
                    </div>
                  </Card>
                </Col>
              </Row>
            </Container>



          </HeaderInfo>
        </div>
      </div>
    </Wrapper>
  );
}

/* Styled Components */
const Wrapper = styled.section`
  width: 100%;
`;

const HeaderInfo = styled.div`
  padding: 70px 0 30px 0;
  @media (max-width: 860px) {
    text-align: center;
  }
  @media (max-width: 400px) {
    text-align: left;
  }
`;