import React from "react";
import styled from "styled-components";
import ProjectBox from "../Elements/ProjectBox";
import products from "../Data/ProductList.json";
// import SAPOTA from "../../assets/img/add/sapota.png";

import MANGO from "../../assets/img/add/supply-mango.png";
import { useNavigate } from "react-router-dom";

export default function Product() {
  const navigate = useNavigate();

  const handleClick = (productId) => {
    navigate(`/product/${productId}`, { replace: true });
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" }); // ✅ Ensure immediate start
    }, 10);
  };

  return (
    <Wrapper id="products">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h2 className="font40 extraBold">Our Awesome Products</h2>
          </HeaderInfo>
          <div className="row textCenter">
            {products.map((product, index) => (
              <div key={index} className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                <ProjectBox
                  img={product.image}
                  title={product.displayname}
                  text={product.description}
                  action={() => handleClick(product.id)}
                />
              </div>
            ))}
          </div>
          <hr></hr>
          <Advertising className="flexSpaceCenter">
            <AddLeft>
              <AddLeftInner>
                <ImgWrapper className="flexCenter">
                  <img className="radius8" src={MANGO} alt="supply mango" title="supply mango" />
                </ImgWrapper>
              </AddLeftInner>
            </AddLeft>

            <AddRight>
              <h4 className="font15 semiBold">A few words about Amalsadi - Kesar Mango</h4>
              <h2 className="font40 extraBold">Mango Benefits</h2>
              <p className="font16">
                Amalsad, a town in Gujarat's Navsari district, is renowned for its cultivation of Kesar mangoes. The Kesar mango, often referred to as the "Queen of Mangoes," is celebrated for its sweet taste, vibrant saffron-colored pulp, and delightful aroma. While the Gir region in Junagadh is traditionally associated with Kesar mangoes, Amalsad has gained recognition for producing this esteemed variety.
              </p>
              <hr />

              <br />
              <Table>
                <tbody>
                  <tr>
                    <td>FOB Price:</td>
                    <td>USD $2.5 - $3.0 per Kg</td>
                  </tr>
                  <tr>
                    <td>Min. Order Quantity:</td>
                    <td>1000 Kg</td>
                  </tr>
                  <tr>
                    <td>Supply Ability:</td>
                    <td>10000 Kg Per Week</td>
                  </tr>
                  <tr>
                    <td>Port:</td>
                    <td>JNPT Mumbai / Mumbai Air</td>
                  </tr>
                  <tr>
                    <td rowSpan="2">Payment Terms:</td>
                    <td>
                      <b>Sea Cargo:</b> 40% Advance & 60% DP / LC AT SIGHT
                    </td>
                  </tr>
                  <tr>

                    <td><b>Air Cargo:</b> 100% Advance (AWB)</td>
                  </tr>
                </tbody>
              </Table>
            </AddRight>
          </Advertising>
        </div>
      </div>

      {/* <div className="lightBg">
        <div className="container">
          <Advertising className="flexSpaceCenter">
            <AddLeft>
              <AddLeftInner>
                <ImgWrapper className="flexCenter">
                  <img className="radius8" src={SAPOTA} alt="Sapota" />
                </ImgWrapper>
              </AddLeftInner>
            </AddLeft>

            <AddRight>
              <h4 className="font15 semiBold">A few words about Sapota - Chikoo</h4>
              <h2 className="font40 extraBold">Sapota - Chikoo Benefits</h2>
              <p className="font16">
                Sapota is high on calories, providing 83 calories per 100 grams. A good source of dietary fiber,
                the pulp functions as an excellent laxative. It is rich in vitamins A, C, niacin, folate, and pantothenic acid,
                along with minerals like iron, potassium, and copper. The plant compounds (tannins) in sapota have strong
                antioxidant, anti-inflammatory, antiviral, antibacterial, and antiparasitic properties.
              </p>
              <hr />

              <h2 className="font20">FOB / CIF Rates</h2>
              <br />
              <Table>
                <tbody>
                  <tr>
                    <td>FOB Price:</td>
                    <td>US$1.4 - US$1.6 Per Kg</td>
                  </tr>
                  <tr>
                    <td>Min. Order Quantity:</td>
                    <td>100 Kg</td>
                  </tr>
                  <tr>
                    <td>Supply Ability:</td>
                    <td>2000 Kg Per Week</td>
                  </tr>
                  <tr>
                    <td>Port:</td>
                    <td>JNPT Mumbai / Mumbai Air</td>
                  </tr>
                  <tr>
                    <td rowSpan="2">Payment Terms:</td>
                    <td>
                      <b>Sea Cargo:</b> 40% Advanced & 60% DP
                    </td>
                  </tr>
                  <tr>
                    
                    <td><b>Air Cargo:</b> 100% Advanced (AWB)</td>
                  </tr>
                </tbody>
              </Table>
            </AddRight>
          </Advertising>
        </div>
      </div> */}

    </Wrapper>
  );
}

/* Styled Components */
const Wrapper = styled.section`
  width: 100%;
`;

const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;

const Advertising = styled.div`
  padding: 100px 0;
  margin: 100px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 1160px) {
    padding: 60px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 30px 0;
    margin: 80px 0 0;
  }
`;

const AddLeft = styled.div`
  width: 50%;
  position: relative;

  @media (max-width: 860px) {
    width: 80%;
    text-align: center;
  }
`;

const AddRight = styled.div`
  width: 50%;

  @media (max-width: 860px) {
    width: 80%;
  }
`;

const AddLeftInner = styled.div`
  width: 100%;
  position: absolute;
  top: -300px;
  left: 0;

  @media (max-width: 1190px) {
    top: -250px;
  }
  @media (max-width: 920px) {
    top: -200px;
  }
  @media (max-width: 860px) {
    position: relative;
    top: -60px;
    left: 0;
  }
`;

const ImgWrapper = styled.div`
  width: 100%;
  padding: 0 15%;
  img {
    width: 100%;
    height: auto;
  }

  @media (max-width: 400px) {
    padding: 0;
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  td {
    padding: 8px;
    border-bottom: 1px solid #ddd;
  }
`;

