import React from "react";
import styled from "styled-components";
import HeaderImage from "../../assets/img/fresh-sapota-chikoo-mangggo-kesar-alphonso.png";


export default function Header() {
  return (
    <Wrapper id="home" className="container flexSpaceCenter">
       <LeftSide className="flexCenter">
        <section>
          <h1 className="extraBold font60">Buy Export-Quality Kesar & Alphonso Mango, Fresh Sapota – Riyaarth Exports</h1>
          <HeaderP className="font15 semiBold">
            <p>
              <strong>Riyaarth Exports</strong> is a leading exporter of high-quality mangoes and sapota (chikoo) from Amalsad, Gujarat, India.
              We ensure premium-grade fruits reach international markets, including Dubai and beyond, maintaining freshness and quality.
            </p>
          </HeaderP>
          {/* <BtnWrapper>
            <a href="/#contact" className="cta-button">Get Started</a>
          </BtnWrapper> */}
        </section>
      </LeftSide>
      <RightSide>
        <ImageWrapper>
          <Img className="radius8" src={HeaderImage} alt="Export quality fresh mango and sapota" title="Export quality fresh mango and sapota" loading="lazy" style={{ zIndex: 9 }} />
        </ImageWrapper>
      </RightSide>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  padding-top: 80px;
  width: 100%;
  min-height: 840px;
  background-size: cover;
  background-repeat: no-repeat;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;

const LeftSide = styled.article`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 2;
    margin: 50px 0;
    text-align: center;
  }
`;

const RightSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 1;
    margin-top: 30px;
  }
`;

const HeaderP = styled.div`
  max-width: 470px;
  padding: 15px 0 50px 0;
  line-height: 1.5rem;
  @media (max-width: 960px) {
    text-align: center;
    max-width: 100%;
  }
`;

// const BtnWrapper = styled.div`
//   max-width: 190px;
//   @media (max-width: 960px) {
//     margin: 0 auto;
//   }
//   .cta-button {
//     display: inline-block;
//     padding: 10px 20px;
//     background-color: #ff6600;
//     color: #fff;
//     text-decoration: none;
//     font-weight: bold;
//     border-radius: 5px;
//     transition: background 0.3s;
//   }
//   .cta-button:hover {
//     background-color: #cc5500;
//   }
// `;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 9;
  @media (max-width: 960px) {
    width: 100%;
    justify-content: center;
  }
`;

const Img = styled.img`
  @media (max-width: 560px) {
    width: 80%;
    height: auto;
  }
`;
